import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
// const config = require('./banking-white-label.json');

const App = React.lazy(() => getTheme().then(() => import('./App')));

async function getTheme() {
  try {
    const response: AxiosResponse = await axios.get(window.__RUNTIME_CONFIG__.REACT_APP_THEME_URL);
    localStorage.setItem("config", JSON.stringify(response.data));
    // localStorage.setItem("config", JSON.stringify(config));
  } catch (error) {
    console.log('Não possivel recuperar o tema', error);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
